import styled from 'styled-components';
import { Device } from '../constants/device';

export const MainMembersContainer = styled.div`
  display: flex;
  max-width: 900px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
  margin-top: 20px;

  ${Device.tablet} {
    gap: 30px;
  }
`;
