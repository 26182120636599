import React from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Section } from '../Section';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { Container } from '../Container';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { Question } from '../Question';

export const FAQ = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project?.faq?.questions.length) {
    return null;
  }

  return (
    <Section id="faq" inverse={props.inverse}>
      <Container>
        {project?.faq.disclaimer && <Disclaimer dangerouslySetInnerHTML={{ __html: project?.faq.disclaimer }} />}
        <FaqContainer>
          {project.faq.questions.map((faq, key) => {
            if (!faq.question || !faq.answer) {
              return null;
            }

            return <Question key={key} question={faq} />;
          })}
        </FaqContainer>
      </Container>
    </Section>
  );
};

const FaqContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 40px;
`;

const Disclaimer = styled.p`
  a {
    color: ${Colors.primary};
  }
`;
