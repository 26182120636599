import React from 'react';
import { useAppContext } from '../state/StateProvider';
import { Jumbotron } from './Jumbotron';
import { Features } from './sections/Features';
import { Contact } from './sections/Contact';
import { FAQ } from './sections/FAQ';
import { About } from './sections/About';
import { Teams } from './sections/Teams';
import { References } from './sections/References';
import { Deployments } from './sections/Deployments';
import { Resources } from './sections/Resources';

export const Home: React.FC = () => {
  const {
    state: { project, activeSections },
  } = useAppContext();

  if (!project) {
    return null;
  }

  let currentSectionIndex = 0;
  return (
    <main>
      <Jumbotron />
      {activeSections.map((section) => {
        const inverse = currentSectionIndex % 2 > 0;
        currentSectionIndex++;

        switch (section) {
          case 'about':
            return <About key={section} inverse={inverse} />;
          case 'features':
            return <Features key={section} inverse={inverse} />;
          case 'references':
            return <References key={section} inverse={inverse} />;
          case 'deployments':
            return <Deployments key={section} inverse={inverse} />;
          case 'faq':
            return <FAQ key={section} inverse={inverse} />;
          case 'teams':
            return <Teams key={section} inverse={inverse} />;
          case 'resources':
            return <Resources key={section} inverse={inverse} />;
          case 'contact':
            return <Contact key={section} inverse={inverse} />;
        }
      })}
    </main>
  );
};
