import React from 'react';

export const CodeListsIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 100C77.6142 100 100 77.6143 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6143 22.3858 100 50 100Z" fill="#2A4CA4" />
    <g>
      <path
        d="M68.8375 52.4283C68.9458 51.6533 69 50.8525 69 50C69 49.1733 68.9458 48.3467 68.8104 47.5717L74.3083 43.49C74.7958 43.1283 74.9313 42.4308 74.6333 41.9142L69.4333 33.3375C69.1083 32.7692 68.4313 32.5883 67.8354 32.7692L61.3625 35.2492C60.0083 34.2675 58.5729 33.4408 56.975 32.8208L56 26.2592C55.8917 25.6392 55.35 25.2 54.7 25.2H44.3C43.65 25.2 43.1354 25.6392 43.0271 26.2592L42.0521 32.8208C40.4542 33.4408 38.9917 34.2933 37.6646 35.2492L31.1917 32.7692C30.5958 32.5625 29.9188 32.7692 29.5938 33.3375L24.4208 41.9142C24.0958 42.4567 24.2042 43.1283 24.7458 43.49L30.2438 47.5717C30.1083 48.3467 30 49.1992 30 50C30 50.8008 30.0542 51.6533 30.1896 52.4283L24.6917 56.51C24.2042 56.8717 24.0688 57.5692 24.3667 58.0858L29.5667 66.6625C29.8917 67.2308 30.5688 67.4117 31.1646 67.2308L37.6375 64.7508C38.9917 65.7325 40.4271 66.5592 42.025 67.1792L43 73.7408C43.1354 74.3608 43.65 74.8 44.3 74.8H54.7C55.35 74.8 55.8917 74.3608 55.9729 73.7408L56.9479 67.1792C58.5458 66.5592 60.0083 65.7325 61.3354 64.7508L67.8083 67.2308C68.4042 67.4375 69.0813 67.2308 69.4063 66.6625L74.6063 58.0858C74.9313 57.5175 74.7958 56.8717 74.2813 56.51L68.8375 52.4283ZM49.5 59.3C44.1375 59.3 39.75 55.115 39.75 50C39.75 44.885 44.1375 40.7 49.5 40.7C54.8625 40.7 59.25 44.885 59.25 50C59.25 55.115 54.8625 59.3 49.5 59.3Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect width="65" height="62" fill="white" transform="translate(17 19)" />
      </clipPath>
    </defs>
  </svg>
);
