import React from 'react';

export const LaboratoryAgendasIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 100C77.6142 100 100 77.6143 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6143 22.3858 100 50 100Z" fill="#2A4CA4" />
    <path
      d="M64.375 35.0417V65.9583H34.625V35.0417H64.375ZM66.7125 30.625H32.2875C31.225 30.625 30.375 31.5083 30.375 32.6125V68.3875C30.375 69.2708 31.225 70.375 32.2875 70.375H66.7125C67.5625 70.375 68.625 69.2708 68.625 68.3875V32.6125C68.625 31.5083 67.5625 30.625 66.7125 30.625ZM47.375 39.4583H60.125V43.875H47.375V39.4583ZM47.375 48.2917H60.125V52.7083H47.375V48.2917ZM47.375 57.125H60.125V61.5417H47.375V57.125ZM38.875 39.4583H43.125V43.875H38.875V39.4583ZM38.875 48.2917H43.125V52.7083H38.875V48.2917ZM38.875 57.125H43.125V61.5417H38.875V57.125Z"
      fill="white"
    />
  </svg>
);
