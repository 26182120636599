import React from 'react';

export const LabManualCreationIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 100C77.6142 100 100 77.6143 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6143 22.3858 100 50 100Z" fill="#2A4CA4" />
    <path
      d="M53.9167 28.3333H31.8334C29.3822 28.3333 27.4167 30.2833 27.4167 32.6666V54.3333H31.8334V32.6666H53.9167V28.3333ZM62.7501 37H40.6667C38.2155 37 36.2501 38.95 36.2501 41.3333V63H40.6667V41.3333H62.7501V37ZM67.1667 45.6666H49.5001C47.0488 45.6666 45.0834 47.6166 45.0834 50V67.3333C45.0834 69.7166 47.0488 71.6666 49.5001 71.6666H67.1667C69.5959 71.6666 71.5834 69.7166 71.5834 67.3333V50C71.5834 47.6166 69.5959 45.6666 67.1667 45.6666Z"
      fill="white"
    />
  </svg>
);
