import React from 'react';

type Props = {
  fill?: string;
};

export const ExpandIcon = ({ fill = '#162C66' }: Props) => (
  <svg width="81" height="31" viewBox="0 0 81 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.34937 3.5C2.73008 1.10854 5.78803 0.28916 8.17949 1.66987L42.8205 21.6699C45.212 23.0506 46.0313 26.1085 44.6506 28.5C43.2699 30.8915 40.212 31.7108 37.8205 30.3301L3.17949 10.3301C0.78803 8.94941 -0.0313467 5.89146 1.34937 3.5Z"
      fill={fill}
    />
    <path
      d="M36.5 28.3301C35.1193 25.9387 35.9387 22.8807 38.3301 21.5L72.9711 1.5C75.3626 0.119289 78.4206 0.938663 79.8013 3.33013C81.182 5.72159 80.3626 8.77954 77.9711 10.1603L43.3301 30.1603C40.9387 31.541 37.8807 30.7216 36.5 28.3301Z"
      fill={fill}
    />
  </svg>
);
