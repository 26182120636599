import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { Device } from '../constants/device';
import { Question as QuestionType } from '../model/Project';
import { sanitize } from 'dompurify';
import { ExpandIcon } from './icons/ExpandIcon';

type Props = {
  question: QuestionType;
};

export const Question = ({ question }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <StyledQuestion onClick={() => setExpanded(!expanded)}>
      <HeaderContainer>
        <h3>{question.question}</h3>
        <ToggleButton expanded={expanded}>
          <ExpandIcon fill={Colors.grey} />
        </ToggleButton>
      </HeaderContainer>
      {expanded && <p dangerouslySetInnerHTML={{ __html: sanitize(question.answer) }}></p>}
    </StyledQuestion>
  );
};

const StyledQuestion = styled.div`
  display: flex;
  width: calc(50% - 20px);
  flex-direction: column;
  padding: 25px;
  border-radius: 20px;
  text-align: center;
  background: ${Colors.white};
  justify-content: start;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;

  &:hover {
    cursor: pointer;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }

  &.inverse {
    background: ${Colors.background};
  }

  h3 {
    color: ${Colors.primaryDark};
  }

  ${Device.tablet} {
    width: 100%;
  }

  a {
    color: ${Colors.primary};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  ${Device.tablet} {
    gap: 0;
    flex-direction: column;
  }
`;

const ToggleButton = styled.button<{ expanded: boolean }>`
  background: transparent;
  border: none;
  cursor: pointer;
  svg {
    width: 20px;
    height: auto;
    transition: transform 0.3s ease;
    transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;
