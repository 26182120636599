import { useAppContext } from '../../state/StateProvider';
import { Container } from '../Container';
import React from 'react';
import styled from 'styled-components';
import { Device } from '../../constants/device';
import { Section } from '../Section';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { ElectronicDocumentationIcon } from '../icons/ElectronicDocumentManagmentIcon';
import { ProjectFeature } from '../../model/enums/ProjectFeature';
import { LaboratoryAgendasIcon } from '../icons/LaboratoryAgendasIcon';
import { CodeListsIcon } from '../icons/CodeListsIcon';
import { LabManualCreationIcon } from '../icons/LabManualCreationIcon';
import { WebIcon } from '../icons/WebIcon';
import { Colors } from '../../constants/colors';

export const Features = (props: ProjectSectionProps): JSX.Element | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project || !project.points.length) {
    return null;
  }

  const getIcon = (feature: ProjectFeature) => {
    switch (feature) {
      case ProjectFeature.Documentation:
        return <ElectronicDocumentationIcon />;
      case ProjectFeature.Agendas:
        return <LaboratoryAgendasIcon />;
      case ProjectFeature.Codelists:
        return <CodeListsIcon />;
      case ProjectFeature.Handbook:
        return <LabManualCreationIcon />;
      case ProjectFeature.Web:
        return <WebIcon />;
    }
  };

  return (
    <Section id="features" inverse={props.inverse}>
      <Container>
        <FeaturesContainer>
          {project.points.map((point, index) => (
            <ProjectPoint key={index}>
              {getIcon(point.key)}
              <h3>{point.title}</h3>
              {point.description}
            </ProjectPoint>
          ))}
        </FeaturesContainer>
      </Container>
    </Section>
  );
};

const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  gap: 100px;
  margin-top: 40px;

  ${Device.tablet} {
    gap: 60px;
  }
`;

const ProjectPoint = styled.div`
  display: flex;
  width: 420px;
  height: 420px;
  flex-direction: column;
  align-items: start;
  padding: 40px;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background: ${Colors.background};

  ${Device.tablet} {
    width: 100%;
    height: auto;
  }

  svg {
    height: 60px;
    width: auto;
  }
`;
