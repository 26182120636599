import React from 'react';

export const ElectronicDocumentationIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 100C77.6142 100 100 77.6143 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6143 22.3858 100 50 100Z" fill="#2A4CA4" />
    <path
      d="M69.5 29.25H30.5C28.1167 29.25 26.1667 31.1625 26.1667 33.5V59C26.1667 61.3375 28.1167 63.25 30.5 63.25H45.6667V67.5H41.3334V71.75H58.6667V67.5H54.3334V63.25H69.5C71.8834 63.25 73.8334 61.3375 73.8334 59V33.5C73.8334 31.1625 71.8834 29.25 69.5 29.25ZM69.5 59H30.5V33.5H69.5V59Z"
      fill="white"
    />
  </svg>
);
