import React from 'react';
import { Team } from '../model/Project';
import { sanitize } from 'dompurify';
import { TeamMemberContainer } from './TeamMemberContainer';
import { MainMembersContainer } from './MainMembersContainer';
import { TeamItemContainer } from './TeamItemContainer';

type TeamItemProps = {
  team: Team;
};
export const CooperationTeam = (props: TeamItemProps) => {
  return (
    <TeamItemContainer>
      <h3 dangerouslySetInnerHTML={{ __html: sanitize(props.team.title) }}></h3>
      <MainMembersContainer>
        {props.team.teamMembers.map((member, key) => (
          <TeamMemberContainer key={key} member={member} itemsPerRow={2} />
        ))}
      </MainMembersContainer>
    </TeamItemContainer>
  );
};
