import React, { ReactElement } from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Container } from '../Container';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { Fonts } from '../../constants/fonts';
import { Device } from '../../constants/device';
import { Section } from '../Section';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { sanitize } from 'dompurify';

export const Contact = (props: ProjectSectionProps): ReactElement | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project || !project.email) {
    return null;
  }

  const email = project.email;

  return (
    <Section id="contact" inverse={props.inverse}>
      <ContactSection>
        {project.contactNote && <p dangerouslySetInnerHTML={{ __html: sanitize(project.contactNote) }} />}
        <EmailLink href={'mailto:' + email}>{email}</EmailLink>
      </ContactSection>
    </Section>
  );
};

const EmailLink = styled.a`
  font-size: 52px;
  font-weight: 700;
  text-decoration: none;
  color: ${Colors.primary};
  position: relative;
  display: inline-block;
  transition: color 0.2s ease-in-out;
  text-align: center;
  margin: 40px auto;

  ${Device.tablet} {
    font-size: 32px;
  }

  ${Device.mobile} {
    font-size: 18px;
  }

  :hover {
    color: ${Colors.primaryDark};
  }
`;

const ContactSection = styled(Container)`
  position: relative;

  p {
    max-width: 60%;
    margin: 0 auto 40px;
    text-align: center;

    ${Device.tablet} {
      max-width: 100%;
    }
  }

  > * {
    z-index: 1;
    position: relative;
  }

  &::after {
    content: '@';
    font-size: max(600px, calc(40vw));
    font-weight: 900;
    line-height: 1;
    color: ${Colors.primaryLight};
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    transform: translate(-50%, 40%);
    font-family: ${Fonts.secondary};
    opacity: 0.1;

    ${Device.tablet} {
      display: none;
    }
  }
`;
