import React from 'react';

export const WebIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 100C77.6142 100 100 77.6143 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6143 22.3858 100 50 100Z" fill="#2A4CA4" />
    <path
      d="M49.9709 21.5833C33.8709 21.5833 20.8334 34.09 20.8334 49.5C20.8334 64.91 33.8709 77.4166 49.9709 77.4166C66.1 77.4166 79.1667 64.91 79.1667 49.5C79.1667 34.09 66.1 21.5833 49.9709 21.5833ZM70.1834 38.3333H61.5792C60.6459 34.8437 59.3042 31.4937 57.5542 28.395C62.9209 30.1537 67.3834 33.7271 70.1834 38.3333ZM50 27.2783C52.4209 30.6283 54.3167 34.3412 55.5709 38.3333H44.4292C45.6834 34.3412 47.5792 30.6283 50 27.2783ZM27.425 55.0833C26.9584 53.2966 26.6667 51.4262 26.6667 49.5C26.6667 47.5737 26.9584 45.7033 27.425 43.9166H37.2834C37.05 45.7591 36.875 47.6016 36.875 49.5C36.875 51.3983 37.05 53.2408 37.2834 55.0833H27.425ZM29.8167 60.6666H38.4209C39.3542 64.1562 40.6959 67.5062 42.4459 70.605C37.0792 68.8462 32.6167 65.3008 29.8167 60.6666V60.6666ZM38.4209 38.3333H29.8167C32.6167 33.6991 37.0792 30.1537 42.4459 28.395C40.6959 31.4937 39.3542 34.8437 38.4209 38.3333V38.3333ZM50 71.7216C47.5792 68.3716 45.6834 64.6587 44.4292 60.6666H55.5709C54.3167 64.6587 52.4209 68.3716 50 71.7216ZM56.825 55.0833H43.175C42.9125 53.2408 42.7084 51.3983 42.7084 49.5C42.7084 47.6016 42.9125 45.7312 43.175 43.9166H56.825C57.0875 45.7312 57.2917 47.6016 57.2917 49.5C57.2917 51.3983 57.0875 53.2408 56.825 55.0833ZM57.5542 70.605C59.3042 67.5062 60.6459 64.1562 61.5792 60.6666H70.1834C67.3834 65.2729 62.9209 68.8462 57.5542 70.605V70.605ZM62.7167 55.0833C62.95 53.2408 63.125 51.3983 63.125 49.5C63.125 47.6016 62.95 45.7591 62.7167 43.9166H72.575C73.0417 45.7033 73.3334 47.5737 73.3334 49.5C73.3334 51.4262 73.0417 53.2966 72.575 55.0833H62.7167Z"
      fill="white"
    />
  </svg>
);
